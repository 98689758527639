"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTodayDateFormat = void 0;
var getTodayDateFormat = function () {
    var todayDate = new Date();
    var m = todayDate.getMonth() + 1;
    var todayDay = todayDate.getDate() < 10
        ? "0" + todayDate.getDate()
        : todayDate.getDate();
    var todayMonth = m < 10 ? "0" + m : m;
    var todayYear = todayDate.getFullYear() < 10
        ? "0" + todayDate.getFullYear()
        : todayDate.getFullYear();
    return todayYear + "-" + todayMonth + "-" + todayDay;
};
exports.getTodayDateFormat = getTodayDateFormat;
