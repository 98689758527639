"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showGoToTop = void 0;
var showGoToTop = function () {
    if (window.pageYOffset > 800) {
        document.getElementById("goToTop").style.display = "flex";
    }
    else {
        document.getElementById("goToTop").style.display = "none";
    }
};
exports.showGoToTop = showGoToTop;
